import React from "react";
import {DialogProperties, DialogSize, UiScope} from "@bloomreach/ui-extension";
import {Avatar, Button, Card, CardActions, CardMedia, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Grid2 from "@mui/material/Unstable_Grid2";
import {FrontifyAsset} from "@frontify/frontify-finder";

interface CmsFieldState {
    assets: Array<FrontifyAsset>
    editMode: boolean
}

interface CmsFieldProperties {
    ui: UiScope
}

export default class CmsField extends React.Component<CmsFieldProperties, CmsFieldState> {

    constructor(props: CmsFieldProperties) {
        super(props);

        this.state = {
            assets: [],
            editMode: false,
        }

        this.openDialog = this.openDialog.bind(this);
        this.removeSingleAsset = this.removeSingleAsset.bind(this);
    }

    componentDidMount() {
        this.getInitialItems(this.props.ui).then(assets => this.setState({assets: assets}));
    }

    async getInitialItems(ui: UiScope) {
        try {
            const brDocument = await ui.document.get();
            this.setState({editMode: brDocument.mode === 'edit'});

            const assets = await ui.document.field.getValue();
            return assets? JSON.parse(assets) : [];

        } catch (error: any) {
            console.error('Failed to register extension:', error.message);
            console.error('- error code:', error.code);
        }
        return [];
    }

    async openDialog(ui: UiScope) {
        try {

            const dialogOptions: DialogProperties = {
                title: 'Frontify finder',
                url: './finder',
                size: DialogSize.Medium,
                value: JSON.stringify(this.state.assets)
            };

            const response = await ui.dialog.open(dialogOptions) as unknown as Array<FrontifyAsset>;
            this.setState({assets: response});
            await this.setValue(response);
        } catch (error: any) {
            if (error.code === 'DialogCanceled') {
                return;
            }
            console.error('Error after open dialog: ', error.code, error.message);
        }

    }

    async setValue(data: any) {
        return this.props.ui.document.field.setValue(JSON.stringify(data));
    }

    async removeSingleAsset(id: string){
        let existingAssets = this.state.assets;
        existingAssets.splice(existingAssets.findIndex(function(i: any){
            return i.id === id;
        }), 1);

        if(existingAssets.length){
            await this.setValue(existingAssets);
            this.setState({assets: existingAssets})
        } else {
            await this.setValue([]);
            this.setState({assets: []})
        }
    }

    async moveUp(index: number){
        const tempAssets = this.state.assets;
        [tempAssets[index], tempAssets[index-1]] = [tempAssets[index-1], tempAssets[index]];
        await this.setValue(tempAssets);
        this.setState({assets: tempAssets});
    }

    async moveDown(index: number){
        const tempAssets = this.state.assets;
        [tempAssets[index], tempAssets[index+1]] = [tempAssets[index+1], tempAssets[index]];
        await this.setValue(tempAssets);
        this.setState({assets: tempAssets});
    }

    render() {
        const {assets, editMode} = this.state;

        return (
          <>
              <Button  disabled={!editMode} variant="outlined" onClick={ async () => { await this.openDialog(this.props.ui) } }
                       startIcon={ <Avatar alt="Browse Frontify" src="/frontify.svg"/> }
              >Browse</Button>
              <Grid2 container spacing={2}>
                  {assets && assets.map(( asset: FrontifyAsset, index: number, {length} )=>{
                      return (
                        <Grid2 xs={12} md={4} key={index}>
                            <Card sx={{maxWidth: 345, padding: "0 20px 20px 20px"}}>
                                <CardActions sx={{justifyContent: 'end'}}>
                                    {editMode &&
                                      <>
                                          {length > 1 && (
                                            <>
                                                <IconButton disabled={index === 0} onClick={async () => {await this.moveUp(index);}}>
                                                  <ArrowUpwardIcon/>
                                                </IconButton>
                                                <IconButton disabled={index === length-1} onClick={async () => {await this.moveDown(index);}}>
                                                  <ArrowDownwardIcon/>
                                                </IconButton>
                                            </>
                                          )}
                                          <IconButton onClick={async () => {await this.removeSingleAsset(asset.id);}}>
                                            <CloseIcon/>
                                          </IconButton>
                                      </>
                                    }
                                </CardActions>
                                <CardMedia
                                  component="img"
                                  image={asset.previewUrl}
                                  alt={asset.filename}
                                  title={asset.filename}
                                />
                            </Card>
                        </Grid2>
                      );
                  })}
              </Grid2>
          </>
        );
    }
}


