import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import UiExtension, {UiScope} from "@bloomreach/ui-extension";
import CmsDialog from "./CmsDialog";
import CmsField from "./CmsField";

export interface UiConfig {
  clientId: string
  allowMultiSelect: boolean
  height?: string
}

async function render() {

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  try {
    const ui: UiScope = await UiExtension.register();

    root.render(
      <BrowserRouter>
        <Routes>
          <Route path="/finder" element={<CmsDialog ui={ui}/>}/>
          <Route path="/" element={<CmsField ui={ui}/>}/>
        </Routes>
      </BrowserRouter>
    );

  } catch (error: any) {

    console.log(error);
    console.error('Failed to register extension:', error.message);
    console.error('- error code:', error.code);

  }
}

render();
