import React from "react";
import {UiScope} from "@bloomreach/ui-extension";
import {UiConfig} from "./index";
import {create, FrontifyAsset} from "@frontify/frontify-finder";
import {Dialog, DialogContent} from "@mui/material";

interface CmsDialogState {
  assets: Array<FrontifyAsset>
  allowMultiSelect: boolean
  clientId: string
  height?: string
}

interface CmsDialogProperties {
  ui: UiScope
}

export default class CmsDialog extends React.Component<CmsDialogProperties, CmsDialogState> {

  constructor(props: CmsDialogProperties) {
    super(props);

    const config: UiConfig = JSON.parse(props.ui.extension.config);

    this.state = {
      assets: [],
      allowMultiSelect: config.allowMultiSelect,
      clientId: config.clientId,
      height: config.height
    }

    this.loadFinder = this.loadFinder.bind(this);
  }

  componentDidMount() {
    this.getInitialItems(this.props.ui).then(assets => this.setState({assets: assets}));
    this.loadFinder();
  }

  async getInitialItems(ui: UiScope) {
    try {
      const options = await ui.dialog.options();
      return JSON.parse(options.value)
    } catch (error: any) {
      console.error('Failed to register extension:', error.message);
      console.error('- error code:', error.code);
    }
    return [];
  }

  async loadFinder() {
    const clientId = this.state.clientId;
    const allowMultiSelect = this.state.allowMultiSelect;

    try {

      const finder = await create({
        clientId: clientId,
        options: {
          allowMultiSelect: allowMultiSelect
        }
      });

      finder.mount(document.getElementById('frontifyFinder') as HTMLElement);

      finder.onAssetsChosen((assets) => {
        if (allowMultiSelect && this.state.assets) {
          let combinedAssets = [...this.state.assets, ...assets];
          this.setState({assets: combinedAssets}, () => {this.props.ui.dialog.close(this.state.assets);});
        } else {
          this.setState({assets: assets}, () => {this.props.ui.dialog.close(this.state.assets);});
        }
      });

      finder.onCancel(() => {
        this.props.ui.dialog.cancel();
      });

    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <>
        <Dialog open={true} fullScreen>
          <DialogContent id="frontifyFinder" sx={{padding: 0}}/>
        </Dialog>
      </>
    );
  }
}